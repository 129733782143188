<template>
  <v-app>
    <Header />
    <div class="full-screen">
      <div class="springImg-container">
        <img
          src="../assets/images/Springdale.jpg"
          class="springImg"
          alt="Descriptive Alt Text"
        />
        <h1 class="h1-overlay">AN EASY WAY TO PARK IN SPRINGDALE</h1>
      </div>
      <div class="black-box">
        <p>
          <span class="blue-text" @click="redirect()">Click here</span> for
          information on parking in Springdale.
        </p>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "Home",

  components: {
    Header,
  },
  methods: {
    redirect() {
      console.log("redirecting");
      window.location.href =
        "https://www.springdaletown.com/414/Parking-In-Springdale";
    },
  },
};
</script>

<style scoped>
/* .springImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
} */
/* Ensure the parent container has relative positioning */
/* .parent-container {
  position: relative;
}

.springImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.springImg::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, black, transparent);
  pointer-events: none; 
} */
/* Container holding the image should have a defined position */
.springImg-container {
  position: relative;
  display: block; /* Using 'block' to ensure it takes full width */
  width: 100%; /* Make sure container takes full width */
  height: 70%; /* or 'block', depending on layout needs */
}

.springImg {
  width: 100%;
  height: 100%; /* Adjust height based on aspect ratio */
  object-fit: cover;
  object-position: center;
}

/* Creating a gradient overlay with a pseudo-element */
.springImg-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, transparent, black);
  pointer-events: none; /* This allows the user to interact with potential content under the gradient */
  height: 101%;
}
.h1-overlay {
  position: absolute;
  top: 75%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Perfect center alignment */
  color: white;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6); /* Text shadow for better readability */
  width: 100%;
  text-align: left;
  margin: 0; /* Remove default margin */
  z-index: 4;
  padding-left: 16px;
}
.black-box {
  background-color: #000;
  padding: 16px 20px;
  text-align: center;
  margin-top: -6px;
  width: 100%;
  height: 30%;
}
p {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 80%;
}
h1 {
  color: #fff;
  font-family: Avega;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
.full-screen {
  width: 100%;
  height: 100vh;
}
.blue-text {
  color: #00a1e0;
  text-decoration: underline;
}
</style>
